import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home Page" />
    <h1>Hi earthlings</h1>
    <p>Welcome to Joc's site.</p>
    <Link to="/bob/">Bob is here..</Link>
  </Layout>
)

export default IndexPage
